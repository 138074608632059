import React, { Component } from "react";
import { connect } from "react-redux";

import Banner from "../utils/Banner";
import "./News.css";
import Newsbox from "../news/Newsbox";

class News extends Component {
  render() {
    var { news } = this.props;
    news = news || [];
    console.log("news", news);

    let srt = (a, b) =>
      a.title.toLowerCase().localeCompare(b.title.toLowerCase());

    var closed = news.filter((n) => n.news_type === "closes").sort(srt);
    var changes = news.filter((n) => n.news_type === "changes").sort(srt);

    var hasNews = changes.length > 0 || closed.length > 0;
    //hasNews = false;

    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">News</h1>
        </Banner>

        <div
          className="container content-container news"
          style={{ marginTop: 40 }}
        >
          <div className="row">
            <div className="col-lg-9 col-md-10 col-sm-12 col-xs-12">
              <p>
                Mit dem flips Gutscheinbuch möchten wir Ihnen ein Jahr lang viel
                Spaß und Freude bereiten. Trotz sorgfältiger Auswahl aller
                Angebote ist es möglich, dass durch Geschäftsaufgabe oder
                Inhaberwechsel einzelne Gutscheine nicht mehr einlösbar sind.
              </p>
              {hasNews && (
                <p>
                  <b>
                    Alle Neuigkeiten zu den flips Angeboten gibt es hier auf
                    einen Blick.
                  </b>
                </p>
              )}

              {!hasNews && (
                <p style={{ marginTop: 40, marginBottom: 40 }}>
                  <b>
                    Aktuell liegen keine Änderungen zu den flips Gutscheinen
                    2024 vor.
                  </b>
                </p>
              )}
            </div>
          </div>

          {/* <Newsbox /> */}

          {hasNews ? (
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <h3 className="page-header">Änderung im Angebot</h3>

                {changes.length > 0 ? (
                  <span className="sub">
                    {changes.map((n, i) => (
                      <div key={i} style={{ marginBottom: 5 }}>
                        <strong>{n.title}</strong> {n.body}
                        <br />
                      </div>
                    ))}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <h3 className="page-header">Einlösung nicht möglich</h3>

                {closed.length > 0 ? (
                  <span className="sub">
                    {closed.map((n, i) => (
                      <div key={i} style={{ marginBottom: 5 }}>
                        <strong>{n.title}</strong> {n.body}
                        <br />
                      </div>
                    ))}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div style={{ marginTop: 60, textAlign: "center" }}>
            <p>
              Bitte helfen Sie mit, alle flips-Nutzer über wichtige
              Veränderungen zu informieren. Sollte ein Gutschein nicht wie
              angegeben eingelöst werden können, senden Sie bitte eine kurze
              Mitteilung an{" "}
              <a href="mailto:service@flips.de">service@flips.de</a>.
            </p>
            <p>
              <b>
                {/* Wir wünschen Ihnen weiterhin viel Vergnügen mit Ihrem flips
                Gutscheinbuch! */}
                Vielen Dank für Ihre Unterstützung!
              </b>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    news: state.news,
  };
};
export default News = connect(mapStateToProps)(News);
