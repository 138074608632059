import React, { Component } from "react";

import Banner from "../utils/Banner";
//import bookImage from '../images/flips_book_2017_text1.png'
//import bookImage from "../images/flips_book_2019_simple.png";
//import bookImage from "../images/flips_2020_p.png";
// import bookImage from "../images/flips_2021_p.png";
//import bookImage from "../images/flips_2023_book_2.png";
import bookImage from "../images/flips_2024_cover.png";
import ReactMarkdown from "react-markdown";

import "./MarkdownPage.css";

export default class MarkdownPage extends Component {
  state = {
    markdown: null,
    title: "",
  };

  componentDidMount() {
    var { uri } = this.props;
    fetch(uri, { credentials: "same-origin" })
      .then((response) => response.text())
      .then((text) => {
        var re = new RegExp("^@@(.*):(.*)", "i");
        var found = text.match(re);

        var title = "";
        if (found && found.length === 3) {
          title = found[2];
          title = title ? title.trim() : "Seite";
        }

        text = text.replace(new RegExp("^@@(.*)", "i"), "");

        this.setState({ markdown: text, title });
      });
  }

  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">{this.state.title}</h1>
        </Banner>

        <div
          className="markdown container content-container"
          style={{ marginTop: 0, marginBottom: 100 }}
        >
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-4">
              <img
                style={{ marginRight: 20, marginTop: 30 }}
                src={bookImage}
                className="img-responsive hidden-xs"
                role="presentation"
              />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-8">
              {this.state.markdown && (
                <ReactMarkdown source={this.state.markdown} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
