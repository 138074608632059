import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
//import App from './Test';

import "bootstrap/dist/css/bootstrap.min.css";
//import 'bootstrap/dist/css/bootstrap-theme.css';

import "./index.css";
import "./theme-red.css";

//import "./theme-winter.css";

ReactDOM.render(<App />, document.getElementById("root"));


