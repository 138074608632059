import React, { Component, useEffect, useMemo, useState } from "react";
import { Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function ValidateRedeem() {
  const params = useSearchParams();
  const id = params.get("id");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [redeem, setRedeem] = useState(null);

  useEffect(() => {
    if (id) {
      fetch(
        `https://vqvdxkuzcbldglhpseri.supabase.co/functions/v1/redeem-verify?id=${id.toUpperCase()}`
      )
        .then((response) => response.json())
        .then((data) => setRedeem(data.data[0]))
        .then(() => setLoading(false))
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [id]);

  if (loading)
    return (
      <div
        className="container content-container"
        style={{ marginTop: 40, marginBottom: 380, fontSize: 18 }}
      ></div>
    );

  return (
    <div>
      <div
        className="container content-container"
        style={{ marginTop: 40, marginBottom: 380, fontSize: 18 }}
      >
        {!redeem && (
          <div>
            <Alert bsStyle="danger">
              <b>Die Transaktions-ID ist ungültig</b>
              <p>Das Einlösen des Gutscheines kann nicht bestätigt werden.</p>
            </Alert>
          </div>
        )}

        {redeem && (
          <div>
            <h2>{redeem.name}</h2>
            <div
              style={{
                width: 200,
                marginTop: 16,
              }}
            >
              <div
                style={{
                  backgroundColor: "#eee",
                  fontSize: 24,
                  textAlign: "center",
                }}
              >
                {redeem.amount}
              </div>
              <div
                style={{
                  backgroundColor: "#004e2f",
                  fontSize: 18,
                  textAlign: "center",
                  color: "white",
                }}
              >
                {redeem.title}
              </div>
            </div>
            <div className="mt-4" style={{ marginTop: 16 }}>
              <small>Eingelöst am:</small> {redeem.redeemedAt}
            </div>
            <div>
              <small>Buch-ID:</small> {redeem.bookId}
            </div>
            <div>
              <small>Transaktion-ID:</small> {redeem.id}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function useSearchParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
