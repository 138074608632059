import React, { Component } from "react";

import MarkdownPage from "../utils/MarkdownPage";

export class AppMd extends Component {
  render() {
    return <MarkdownPage uri="/datenschutz/app.md" />;
  }
}

export class PartnerMd extends Component {
  render() {
    return <MarkdownPage uri="/datenschutz/partner.md" />;
  }
}

export class VersandMd extends Component {
  render() {
    return <MarkdownPage uri="/datenschutz/versand.md" />;
  }
}
