import React from "react";
//import { BounceLoader } from "react-spinners";

export default class SmartButton extends React.Component {
  render() {
    var {
      loading,
      onClick,
      children,
      disabled,
      loadingIconColor,
      ...others
    } = this.props;
    //console.log(loading);
    if (!loading) loading = false;

    var props = {};
    if (onClick) {
      props.onClick = onClick;
    }

    disabled = disabled || loading;
    loadingIconColor = loadingIconColor || "#007bff";

    return (
      <button {...others} disabled={disabled} {...props}>
        sss
      </button>
    );
  }
}
