import React, { Component } from "react";

import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { IndexLinkContainer, LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import "./Main.css";

import flipsLogo from "./images/flips_logo_2019_1.png";
import flipsSmallLogo from "./images/flips_logo_mobile_2019.png";

// import flipsSmallLogo from "./images/flips_logo_mobile_2020.png";
// import flipsLogo from "./images/flips_logo_2020.png";

import Footer from "./Footer";

export default class Main extends Component {
  render() {
    return (
      <div>
        <div id="wrap">
          <Titlebar />
          {this.props.children}
        </div>
        <Footer />
      </div>
    );
  }
}

export class Titlebar extends Component {
  state = { navExpanded: false };

  setNavExpanded() {
    this.setState({ navExpanded: !this.state.navExpanded });
  }

  closeNav() {
    this.setState({ navExpanded: false });
  }

  render() {
    return (
      <Navbar
        fixedTop={true}
        onToggle={(e) => this.setNavExpanded()}
        expanded={this.state.navExpanded}
      >
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/" style={{ marginTop: -14 }}>
              <img
                className="hidden-xs"
                src={flipsLogo}
                alt="FLIPS"
                style={{ height: 58, marginTop: 0 }}
              />
              <img
                className="hidden-lg hidden-sm hidden-md"
                src={flipsSmallLogo}
                alt="FLIPS"
                style={{ height: 48, marginTop: 0 }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight onSelect={(e) => this.closeNav()}>
            <IndexLinkContainer to="/" className="hidden-md">
              <NavItem eventKey={1}>Startseite</NavItem>
            </IndexLinkContainer>

            <LinkContainer to="/angebote">
              <NavItem eventKey={3}>Angebote</NavItem>
            </LinkContainer>

            {/* <NavDropdown
              eventKey={3.0}
              title="Angebote"
              id="basic-nav-dropdown"
              active={false}
              style={{}}
              className="flips-nav-dropdown"
            >
              <LinkContainer to="/angebote" active={false}>
                <MenuItem eventKey={3.1} active={false}>
                  Angebote 2023
                </MenuItem>
              </LinkContainer>
              <LinkContainer to="/angebote2024" active={false}>
                <MenuItem eventKey={3.2} active={false}>
                  Angebote 2024
                </MenuItem>
              </LinkContainer>
            </NavDropdown> */}

            <LinkContainer to="/apps">
              <NavItem eventKey={2}>App</NavItem>
            </LinkContainer>
            {/* <LinkContainer to='/angebote'>
              <NavItem eventKey={3}>Angebote</NavItem>
            </LinkContainer> */}

            {/* <NavDropdown
              eventKey={3.0}
              title="Angebote"
              id="basic-nav-dropdown"
              active={offersActive}
              style={{}}
              className="flips-nav-dropdown"
            > */}

            {/* <LinkContainer to="/angebote2021" active={false}>
                <MenuItem eventKey={3.1}>Angebote 2021</MenuItem>
              </LinkContainer> */}

            {/* <LinkContainer to="/angebote" active={false}>
                <MenuItem eventKey={3.0}>Angebote 2020</MenuItem>
              </LinkContainer> */}

            {/*           

              <MenuItem divider />

              <LinkContainer to="/angebote/0" active={false}>
                <MenuItem eventKey={3.2}>Freizeit Braunschweig</MenuItem>
              </LinkContainer>

              <LinkContainer to="/angebote/1" active={false}>
                <MenuItem eventKey={3.3}>Gastronomie</MenuItem>
              </LinkContainer>

              <LinkContainer to="/angebote/2" active={false}>
                <MenuItem eventKey={3.4}>Handel & Dienstleistung</MenuItem>
              </LinkContainer>

              <LinkContainer to="/angebote/3" active={false}>
                <MenuItem eventKey={3.5}>Regionale Teilnehmer</MenuItem>
              </LinkContainer> */}

            {/* </NavDropdown> */}

            {/* <LinkContainer to="/reservieren">
              <NavItem eventKey={7}>Reservieren</NavItem>
            </LinkContainer> */}

            <LinkContainer to="/bestellen">
              <NavItem eventKey={7}>Bestellen</NavItem>
            </LinkContainer>

            <LinkContainer to="/verkaufsstellen">
              <NavItem eventKey={4}>Verkaufsstellen</NavItem>
            </LinkContainer>
            <LinkContainer to="/news">
              <NavItem eventKey={5}>News</NavItem>
            </LinkContainer>

            {/* <LinkContainer to="/rätsel">
              <NavItem eventKey={71}>Rätsel</NavItem>
            </LinkContainer> */}

            <LinkContainer to="/regeln">
              <NavItem eventKey={6}>Regeln</NavItem>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
