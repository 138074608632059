import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => (
  <footer className="footer">
    <div className="flips-footer">
      <div className="container">
        <h3>flips Gutscheinbuch – das lohnt sich immer.</h3>
        <Link to="/kontakt">Kontakt</Link>{" "}
        <Link to="/impressum">Impressum</Link>{" "}
        <Link to="/datenschutz">Datenschutz</Link>
        <div className="row flips-footer-body" style={{ marginTop: 20 }}>
          <div className="col-sm-12 col-xs-12">
            <div className="footer-widget footer-widget-contacts">
              <div style={{ display: "inline-block", marginBottom: 10 }}>
                <span
                  className="glyphicon glyphicon-envelope"
                  aria-hidden="true"
                />
                <span style={{ marginRight: 30 }}>
                  <a href="mailto:service@flips.de">service@flips.de</a>
                </span>
              </div>
              <div style={{ display: "inline-block" }}>
                <span
                  className="glyphicon glyphicon-earphone"
                  aria-hidden="true"
                />
                <a href="tel:+495112702643">0511 2702643</a>
              </div>
            </div>
          </div>

          {/*<div className="col-sm-6 col-xs-12">
          <div className="footer-widget footer-widget-contacts">
          <a href="#">Kontakt</a><br/>
          <a href="#">Impressum</a>
          </div>
          </div>*/}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
