import React, { useEffect } from "react";
import { Provider } from "react-redux";

import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import Main from "./Main";
import Apps from "./apps/Apps";
import Home from "./home/Home";
import News from "./news/News";

import OffersPreview, { NextYearOffersPreview } from "./offers/OffersPreview";
import Contact from "./pages/Contact";
import { AppMd, PartnerMd } from "./pages/Datenschutz";
import ImpressumMd from "./pages/ImpressumMd";
import NotFound from "./pages/NotFound";
import Order from "./pages/Order";
import Reservation from "./pages/Reservation";
import Rules from "./rules/Rules";
import Sellpoints from "./sells/Sellpoints";

import store from "./store";
import ValidateRedeem from "./pages/ValidateRedeem";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/">
            <Main>
              <ScrollToTop />
              {/* <div>
                Test
                <iframe
                  src="https://publish.flyeralarm.digital/flips-gutscheinbuch-2023/"
                  title="flips Gutscheinbuch 2023"
                  width="100%"
                  height="400"
                  style={{ border: "none" }}
                  frameBorder="0"
                  allowfullscreen="true"
                ></iframe>
              </div> */}
              <Switch>
                <Route path="/" component={Home} exact />
                {/* <Route path="/angebote" component={OffersPreview} /> */}
                {/* <Route path="/angebote2024" component={NextYearOffersPreview} /> */}
                <Route path="/angebote" component={NextYearOffersPreview} />

                <Route path="/apps" component={Apps} />
                <Route path="/verkaufsstellen" component={Sellpoints} />
                <Route path="/regeln" component={Rules} />
                <Route path="/reservieren" component={Reservation} />
                <Route path="/bestellen" component={Order} />
                <Route path="/news" component={News} />

                <Route path="/kontakt" component={Contact} />
                <Route path="/impressum" component={ImpressumMd} />
                <Route path="/datenschutz" component={ImpressumMd} />
                <Route path="/datenschutz-app" component={AppMd} />
                <Route path="/datenschutz-gp" component={PartnerMd} />

                <Route path="/validate" component={ValidateRedeem} />

                <Redirect from="/index.html" to="/" />
                <Redirect from="/apps.html" to="apps" />
                <Redirect from="/was_wann_wo.html" to="apps" />
                <Redirect from="/verkaufsstellen.html" to="verkaufsstellen" />
                <Redirect from="/news.html" to="news" />
                <Redirect from="/regeln.html" to="regeln" />
                <Redirect from="/kontakt.html" to="kontakt" />
                <Redirect from="/impressum.html" to="impressum" />
                <Redirect from="/newsletteranmeldung.html" to="impressum" />
                <Redirect from="/reservieren.html" to="reservieren" />
                <Redirect from="/bestellen.html" to="bestellen" />
                <Redirect from="/angebote2024.html" to="angebote2024" />
                <Redirect from="/datenschutz.html" to="datenschutz" />
                <Route path="*" component={NotFound} />
              </Switch>
            </Main>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
