import React, { Component } from "react";

const years = {
  "2016_gs":
    "http://greenstones.de/barometer/live/ess/56539a63b106bb07a99a151b/images",
  2016: "/images/56539a63b106bb07a99a151b",
  preview2017: "/images/preview2017",
  ads2017: "/images/ads2017",
  2017: "/images/images2017",
  preview2018: "/images/preview2018",
  preview2019: "/images/preview2019",
  preview2019_ads: "/images/preview2019_ads",
  preview2018_ads: "/images/preview2018_ads",
  2018: "/images/images2018",
  2019: "/images/images2019",
  preview2020: "/images/preview2020",
  preview2020_ads: "/images/preview2020_ads",
  2020: "/images/images2020",
  preview2021: "/images/preview2021",
  preview2021_ads: "/images/preview2021_ads",
  preview2022: "/images/preview2022",
  preview2022_ads: "/images/preview2022_ads",
  preview2023: "/images/preview2023",
  preview2023_ads: "/images/preview2023_ads",
  preview2024: "/images/preview2024",
  preview2024_ads: "/images/preview2024_ads",
};

export function getImageURL(year, image) {
  return `${years[year]}/${image}`;
}

export default class Image extends Component {
  render() {
    let { year, image, ...rest } = this.props;
    let url = getImageURL(year, image);

    return <img {...rest} src={url} />;
  }
}
