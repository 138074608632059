import React, { Component } from 'react';

import Banner from '../utils/Banner'
import contactImage from '../images/contact.png'


export default class Contact extends Component {
  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">
            Kontakt
          </h1>
        </Banner>



        <div className="container content-container" style={{marginTop:40, marginBottom: 380, fontSize:18}}>

          <div className="row">

            <div className="col-lg-1 1col-lg-offset-1  col-md-1 1col-md-offset-1 col-sm-2">
              <img
                style={{marginRight: 20, marginTop:0}}
                src={contactImage}
                className="img-responsive hidden-xs" role="presentation" />
              </div>

            <div className="col-lg-6 col-md-6 col-sm-8">


          <p>Bei Fragen zum flips Gutscheinbuch oder zur flips App hilft Ihnen unser Team gerne weiter. Über Feedback und Anregungen zu den Angeboten freuen wir uns.

          Senden Sie uns einfach eine E-Mail an die Adresse <a href="mailto:service@flips.de">service@flips.de</a>.
        </p>

        </div>
        </div>

        </div>
    </div>
    );
  }
}
