import React, { Component } from "react";
import { Link } from "react-router-dom";

import Banner from "../utils/Banner";

import googlePlayBadge from "./google-play-badge.svg";
import appStoreBadge from "./app-store-badge.svg";

import appImage from "../images/flips_app2020.jpg";

import "./Apps.css";

export default class Apps extends Component {
  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">App für iOS und Android</h1>

          <div className="row">
            <div className="col-lg-9 col-md-10 col-sm-12 col-xs-12">
              <p
                className="flips-banner-text"
                style={{ marginTop: 2, marginBottom: 0 }}
              >
                {/* Die App für die flips Ausgabe 2019 ist ab Januar verfügbar. Für
                die flips App 2018 steht zeitgleich ein Update bereit. */}
                {/* Die flips App steht für Sie kostenfrei zum Download bereit. */}
                {/* Für die&nbsp;flips&nbsp;App&nbsp;2017 ist ein Update erhältlich. */}
                {/* Die App für die flips Ausgabe 2018 ist ab Mitte Januar
                verfügbar. Für die flips App 2017 steht zeitgleich ein Update
                bereit. */}
                {/*
                Ab sofort steht die flips App 2017 kostenfrei für Sie zum
                Download bereit. Für die App 2016 ist ein Update erhältlich. */}
                {/* Die flips App steht für Sie kostenfrei zum Download bereit. */}
                {/* Die App für die flips Ausgabe 2020 ist ab Januar verfügbar. Für
                die flips App 2019 steht zeitgleich ein Update bereit. */}
                {/* Die App für die flips Ausgabe 2020 ist jetzt verfügbar. Für die
                flips App 2019 steht ein Update bereit. */}
                {/* Die App für die flips Ausgabe 2022 ist ab Januar verfügbar. Für
                die flips App 2021 steht zeitgleich ein Update bereit. */}
                {/* Die App für die flips Ausgabe 2023 ist ab Januar verfügbar. Für die flips App 2022 steht zeitgleich ein Update bereit. */}
                {/* Die App für die flips Ausgabe 2024 ist ab Januar verfügbar. Für
                die flips App 2023 steht zeitgleich ein Update bereit. */}
                Die App für die flips Ausgabe 2024 ist jetzt verfügbar. Für die
                flips App 2023 steht ein Update bereit.
              </p>
            </div>
          </div>
        </Banner>

        <div
          className="container apps content-container"
          style={{ marginTop: 0, marginBottom: 60 }}
        >
          <div className="row apps-block">
            <div className="col-md-5 col-sm-5 vcenter1">
              <div className="device-container1" style={{}}>
                <img
                  role="presentation"
                  src={appImage}
                  className="img-responsive"
                  style={{ maxHeight1: 300, marginTop: 40, paddingRight: 0 }}
                />
              </div>
            </div>

            <div className="col-md-7 col-sm-7 vcenter">
              <div className="apps-desc" style={{}}>
                <p style={{ fontWeight: "bold", marginTop: 30 }}>
                  {/* Was, Wann, Wo - Einfach und bequem das Einlösen der flips
                  Gutscheine organisieren! */}
                  Die praktische Was-Wann-Wo-App ist die ideale Ergänzung zum
                  flips Gutscheinbuch. Sie ermöglicht das schnelle Auffinden von
                  Angeboten, unterstützt bei der Planung der nächsten flips
                  Besuche und enthält alle aktuellen Gutscheindaten.
                </p>
                <p>
                  {/* Die praktische Was-Wann-Wo-App ist die ideale Ergänzung zum
                  flips Gutscheinbuch Braunschweig. Sie erleichtert Ihnen die
                  Gutscheinnutzung, egal ob Sie einfach nur den Überblick über
                  Ihre verfügbaren Gutscheine behalten wollen, schneller und
                  komfortabler nach Angeboten suchen oder zusätzliche
                  Informationen abrufen möchten. */}
                  Feature im Überblick:
                </p>

                <ul>
                  <li>Tagesgültige Gastronomieangebote anzeigen</li>
                  <li>Standortbezogen Gutscheine finden </li>
                  <li> Kartenansicht und Navigation</li>
                  <li>Persönliche Merkliste</li>
                  <li>Terminkalender</li>
                  <li>Neuigkeiten sofort erfahren</li>
                  <li>Freunde zum Flipsen einladen</li>
                  <li>Eingelöste Gutscheine als verwendet markieren</li>
                </ul>

                {/* <p style={{}}>
                  Die flips App ersetzt nicht das Buch. Einlösbar sind
                  ausschließlich die heraustrennbaren Gutscheine im flips Heft.
                </p> */}

                <div style={{ marginTop: 20 }}>
                  <Link to="/datenschutz-app">Datenschutzhinweise</Link>
                </div>

                <div className="badges">
                  <a
                    className="badge-link"
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.greenstones.flips"
                  >
                    <img src={googlePlayBadge} alt="" />
                  </a>{" "}
                  <a
                    className="badge-link"
                    target="_blank"
                    href="https://itunes.apple.com/de/app/flips/id599955909?mt=8"
                  >
                    <img src={appStoreBadge} alt="" />
                  </a>
                </div>

                <p style={{ marginTop: 30 }}>
                  Über Feedback und Anregungen zur flips App freuen wir uns:{" "}
                  <a href="mailto:service@flips.de">service@flips.de</a>.
                </p>

                <p>
                  <strong style={{}}>Viel Spaß mit der flips App!</strong>
                </p>
              </div>
            </div>
          </div>
          {/*
          <h3
            className="main-color"
            style={{ textAlign: "center", marginTop: 40 }}
          >
            Das alles bietet die flips App!
          </h3>
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-md-4 col-sm-6">
              Die Gastronomie-Gutscheine sortieren Sie sich mithilfe cleverer
              Filterfunktionen ganz individuell nach Ihrem Bedarf. So können Sie
              sich die Gutscheine tagesaktuell, gezielt nach der Art des
              Angebotes und/oder nach der Entfernung zum Ihrem aktuellen Ort
              anzeigen lassen. Damit erhalten Sie eine erste Orientierung. Bitte
              vergewissern Sie sich immer durch einen Blick auf den
              Gutscheintext bzw. in die Regeln zur Nutzung der Gutscheine, ob
              alle Voraussetzungen für eine Einlösung erfüllt sind. Um die von
              Ihnen bereits eingelösten Gutscheine von der Filtersuche
              auszuschließen, markieren Sie diese einfach als „verwendet“.
            </div>

            <div className="col-md-4 col-sm-6">
              <p>
                Möchten Sie was erleben? Dann finden Sie unter „Termine“ eine
                kalendarische Übersicht zahlreicher Events und
                Sportveranstaltungen, auf die Sie flips mitnimmt. Für einen
                vollständigen Überblick über alle flips Aktivangebote schauen
                Sie direkt in die Rubriken „Freizeit Braunschweig“ und
                „Regionale Teilnehmer“.
              </p>
            </div>

            <div className="col-md-4 col-sm-6">
              <p>
                Ihre Favoriten setzen Sie am besten gleich auf Ihre persönliche
                Merkliste, so haben Sie Ihre nächsten flips Besuche immer im
                Blick. Für den Versand von Einladungen an Familie oder Freunde
                steht Ihnen die App ebenfalls zur Seite.
              </p>
              <p>
                Sind Sie unterwegs, zeigt Ihnen die App alle Gutscheine in Ihrer
                unmittelbaren Nähe an. Aktuelle Angebots-Änderungen finden Sie
                unter „News“.
              </p>
            </div>
          </div> */}

          {/* <div style={{ marginTop: 40, textAlign: "center" }}>
            <div>
              <p>
                Über Feedback und Anregungen zur flips App freuen wir uns:{" "}
                <a href="mailto:service@flips.de">service@flips.de</a>.
              </p>
              <p>
                <strong style={{}}>
                  {" "}Wir wünschen viel Vergnügen mit der flips App!
                </strong>
              </p>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
