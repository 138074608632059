import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Newsbox.css";
import SmartButton from "./SmartButton";

const errors = {
  emailNotValid: "Sie haben eine ungültige E-Mail-Adresse eingegeben.",
  emailAlreadySubmitted:
    "Diese E-Mail-Adresse ist bereits an unserem Newsletter angemeldet."
};

export default class Newsbox extends Component {
  state = { formView: "form", loading: false };

  submitForm(e) {
    //console.log("submitForm:", this.refs.email.value + "<");

    if (!this.refs.email.value) return;

    var email = this.refs.email.value.trim();
    var emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    console.log("email:", email + "<", emailValid);

    if (!emailValid) {
      this.setState({ formView: "emailNotValid" });
      return;
    }

    this.setState({ loading: true });

    // fetch("https://email-marketing.1und1.de/widget/iframe/37xF/9mV")
    //   .then(r => {
    //     return r.text();
    //   })
    //   .then(txt => {
    //     console.log(txt);
    //   });

    fetch("newsletter_1und1.php", {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `email=${email}`
    })
      .then(r => {
        return r.json();
      })
      .then(r => {
        console.log("text ", r);
        if (r.error) {
          this.setState({ formView: "error", msg: r.error, loading: false });
        } else {
          this.setState({
            formView: "submitted",
            msg: r.success,
            loading: false
          });
        }
        // if (r.search('<div style="color:red">') === -1) {
        //   if (r.search("Adresse ist bereits") === -1) {
        //     this.setState({ formView: "submitted" });
        //   } else {
        //     this.setState({ formView: "emailAlreadySubmitted" });
        //   }
        // } else {
        //
        // }
      })
      .catch(e => {
        console.log(e);
        this.setState({ formView: "postError", loading: false });
      });
  }

  renderMessage() {
    var { msg } = this.state;
    
    return (
      <div className="newsbox">
        <div className="newsbox-block">
          <div className="newsbox-subheader">
            {/* <b>Vielen Dank für Ihre Anmeldung!</b>
            <p />

            Bitte überprüfen Sie Ihren E-Mail-Posteingang und klicken Sie auf
            den Link, um Ihre Anmeldung zu bestätigen. */}

            <div dangerouslySetInnerHTML={{ __html: msg }} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    let { formView } = this.state;

    if (formView === "submitted") {
      return this.renderMessage();
    }

    var err = null;
    if (formView === "emailNotValid") {
      err = errors.emailNotValid;
    }

    if (formView === "emailAlreadySubmitted") {
      err = errors.emailAlreadySubmitted;
    }

    if (formView === "postError") {
      err =
        "Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an uns: service@flips.de";
    }

    if (formView === "error") {
      err = this.state.msg;
    }

    return (
      <div className="newsbox">
        <div className="newsbox-block">
          <h3>Bleiben Sie auf dem Laufenden!</h3>

          <div className="newsbox-subheader">
            Jetzt Newsletter abonnieren und aktuelle Infos zu den Gutscheinen
            erhalten.
          </div>

          <div className="newsbox-form">
            <div className="input-group col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <input
                ref="email"
                type="email"
                disabled={this.state.loading}
                className="form-control input-lg"
                placeholder="Ihre E-Mail Adresse"
              />
              <span className="input-group-btn">
                <button
                  className={
                    "btn btn-info input-lg " +
                    (this.state.loading ? "disabled" : "")
                  }
                  type="button"
                  disabled={this.state.loading}
                  onClick={e => this.submitForm(e)}
                >
                  Abonnieren{this.state.loading ? "..." : ""}
                </button>
              </span>
            </div>
            {err ? <p className="form-error">{err}</p> : ""}
          </div>

          <div className="newsbox-footer">
            {/* Der Newsletter kann jederzeit wieder abbestellt werden.{" "}
            <br className="" /> Ihre Daten werden ausschließlich für den Versand
            des Newsletters verwendet. */}
            Ihre Daten werden ausschließlich für den Versand des Newsletters
            verwendet.
            <br />
            Informationen zum Versandverfahren, zum Datenschutz, zur
            Erfolgsmessung, zu Ihren Widerspruchsrechten und zu der
            Protokollierung der Anmeldung erhalten Sie in unserer{" "}
            <Link to="/datenschutz">Datenschutzerklärung</Link>.
          </div>
        </div>
      </div>
    );
  }
}
