import React, { Component } from 'react';

import './Banner.css'

export default class Banner extends Component {
  render() {
    return (
      <div className="flips-banner flips-banner-bg">
        <div className="container">
          <div
            style={{ marginTop: 20, marginBottom: 20}}>
          {this.props.children}
            </div>
          </div>
        </div>


    );
  }
}
