import React, { Component } from "react";

import { Link } from "react-router-dom";
import Banner from "../utils/Banner";
//import bookImage from "../images/flips_book_2018_5.png";
//import bookImage from "../images/flips_2020_p.png";
import bookImage from "../images/flips_2024_cover.png";
export default class NotFound extends Component {
  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">
            Seite nicht gefunden (404-Fehler)
          </h1>
        </Banner>

        <div
          className="container content-container"
          style={{ marginTop: 0, marginBottom: 100, fontSize: 18 }}
        >
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-4">
              <img
                style={{ marginRight: 20, marginTop: 30 }}
                src={bookImage}
                className="img-responsive hidden-xs"
                role="presentation"
              />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-8">
              <h3 className="flips-header">
                Diese Seite konnte nicht gefunden werden.
              </h3>
              <p>
                Gründe dafür könnten sein, dass Sie eine falsche oder veraltete
                URL aufgerufen haben – bitte überprüfen Sie diese noch einmal.{" "}
              </p>

              <Link
                className="btn btn-info"
                style={{ fontSize: 18, marginTop: 30 }}
                to="/"
              >
                Zur FLIPS Startseite
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
