import React, { Component } from "react";
import { connect } from "react-redux";

import Newsbox from "../news/Newsbox";
import Banner from "./BannerOrder";
import OffersNY, { NextYearOffers, ThisYearOffers } from "./NextYearOffers";

class Home extends Component {
  render() {
    return (
      <div>
        {/* <NewsBanner /> */}

        <Banner />

        <div className="container content-container">
          {/* <GastroFilterBox
            style={{ marginTop: 50 }}
            onSearch={e => {
              this.props.gotoGastro();
            }}
          /> */}

          {/* <CovidText />
          <Covid /> */}

          {/* <Offers /> */}

          {/* <ThisYearOffers /> */}

          <NextYearOffers />

          {/* <div
            className="offers-container"
            style={{ marginTop: 80, marginBottom: 80 }}
          >
            <h1 className="offers-header">Angebote 2024</h1>
            <div className="offers-subheader">
              Hier können ab Mitte November 2023 alle Angebote der Ausgabe 2024
              eingesehen werden!
            </div>
          </div> */}

          {/* <BookPreview /> */}
          <div style={{ marginBottom: 50 }} />

          {/* <GastroFilterBox
            style={{ marginTop: 50 }}
            onSearch={(e) => {
              this.props.gotoGastro();
            }}
          /> */}
          {/* <Newsbox /> */}

          <div style={{ marginBottom: 50 }} />

          {/* <PopupNewsbox /> */}

          <Newsbox />
          <div style={{ marginBottom: 50 }} />
        </div>
      </div>
    );
  }
}

const BookPreview = () => (
  <div>
    <h1 className="offers-header">flips 2024 zum Durchblättern</h1>
    <div
      className="row"
      style={{
        marginTop: 30,
      }}
    >
      <div
        className="col-lg-8 col-md-6 col-lg-offset-2 col-md-offset-3"
        style={{ backgroundColor1: "#fef7ec" }}
      >
        <div style={{}}>
          {/* <iframe
          width="100%"
          height="300"
          style={{}}
          // src="https://epaper.bw-h.de/flips/flips-gutscheinbuch2020/embed/index.html?bg=transparent&max=80&fs=1&sp=2"
          src="https://epaper.bw-h.de/flips/flips-gutscheinbuch2022/embed/index.html?bg=transparent&max=16&fs=1&sp=2"
          //  src="https://epaper.bw-h.de/flips/flips-gutscheinbuch2019/embed/index.html?bg=transparent&max=80&fs=1&sp=2"
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
          allowFullScreen="true"
        /> */}

          <iframe
            src="https://publish.flyeralarm.digital/flips-gutscheinbuch-2024/"
            title="flips Gutscheinbuch 2024"
            width="100%"
            height="400"
            style={{ border: "none" }}
            frameBorder="0"
            allowFullScreen="true"
          ></iframe>
          {/* <iframe
          width="100%"
          height="300"
          style={{}}
          src="https://epaper.bw-h.de/flips/flips-gutscheinbuch2018/embed/index.html?bg=transparent&max=16&fs=1&sp=2"
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
          allowFullScreen="true"
        /> */}
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    gotoGastro: () => {
      dispatch({ type: "SHOW_GASTRO_FILTER", showFilterModal: true });
      ownProps.router.push({
        pathname: `/angebote/1`,
      });
    },
  };
};

Home = connect(mapStateToProps, mapDispatchToProps)(Home);
export default Home;
