import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Banner.css";
// import bookImage from "../images/flips_book_2017_text1.png";
// import zettelImage from "../images/flips_book_2017.png";
// import book2018Image from "../images/flips_book_2018_2.png";
// import flips_placeholder from "../images/flips_placeholder.png";
// import reserveImage from "../images/flips_slider_2018.png";
// import appImage from "../images/flips_app1.png";
//
//

//import bookImage from "../images/flips_book_2019_1.png";
import bookImage from "../images/flips_2024_cover.png";

class Slide1 extends Component {
  render() {
    return (
      <div className="container">
        <div className="flips-slider-content">
          <div className="row" style={{}}>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 vcenter ">
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <Link to="/bestellen">
                  <img
                    role="presentation"
                    src={bookImage}
                    className="img-responsive center-block "
                    style={{ maxHeight: 346 }}
                  />
                </Link>
              </div>
            </div>

            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 vcenter">
              <h1 className="flips-slider-header" style={{ color: "#475269" }}>
                Das flips Gutscheinbuch 2024 ist da!
              </h1>

              <p className="flips-slider-text" style={{ marginTop: 18 }}>
                <b>
                  Mit flips unterwegs sein heißt: Spaß haben, Neues entdecken
                  und Geld sparen.
                </b>
              </p>

              {/* <p className="flips-slider-text" style={{ marginBottom: 0 }}>
                Sichern Sie sich Ihr flips Gutscheinbuch 2019!
              </p>
*/}
              <p
                className="flips-slider-text1 "
                style={{ marginTop: 22, paddingBottom: 16 }}
              >
                <Link
                  to="/bestellen"
                  className="btn btn-info btn-red1"
                  style={{ fontSize: 18 }}
                >
                  Jetzt bestellen
                </Link>
              </p>

              {/* <Subblock /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Slide2019 extends Component {
  render() {
    return (
      <div className="container">
        <div className="flips-slider-content">
          <div className="row" style={{}}>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 vcenter">
              {/* <Link to="/bestellen"> */}
              <img
                role="presentation"
                src={bookImage}
                className="img-responsive center-block"
                style={{ maxHeight: 366 }}
              />
              {/* </Link> */}
            </div>

            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 vcenter">
              <h1 className="flips-slider-header" style={{ color: "#a81d21" }}>
                Auf geht’s!
              </h1>
              <p className="flips-slider-text" style={{ marginBottom: 0 }}>
                Braunschweig hat viel zu bieten. Das flips Gutscheinbuch 2019
                nimmt Sie mit auf eine Entdeckungsreise durch die Stadt und ihre
                Region. Mit mehr als 450 Gutscheinen aus Gastronomie, Handel und
                Freizeit. Freuen Sie sich auf ein spannendes und
                abwechslungsreiches Jahr.
              </p>
              <p className="flips-slider-text" style={{ marginTop: 18 }}>
                <b>
                  Mit flips unterwegs sein heißt: Neues entdecken, Spaß haben
                  und dabei Geld sparen!
                </b>
              </p>

              {/* <p className="flips-slider-text" style={{ marginBottom: 0 }}>
                Sichern Sie sich Ihr flips Gutscheinbuch 2019!
              </p>

              <p className="flips-slider-text" style={{ marginTop: 8 }}>
                <Link
                  to="/bestellen"
                  className="btn btn-info btn-red1"
                  style={{ fontSize: 18 }}
                >
                  Jetzt bestellen!
                </Link>
              </p> */}

              {/* <Subblock /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class Banner extends Component {
  render() {
    return (
      <div className="flips-slider">
        <Slide1 />
      </div>
    );
  }
}
